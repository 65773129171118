import React, { useEffect, useState } from "react"
import Img from "gatsby-image"
import styled from "styled-components"

import arrowLeft from "../../assets/icons/arrowLeft.png"
import arrowRight from "../../assets/icons/arrowRight.png"

import { CloseIcon } from "./ownershipModalButton"

const ImageModal = ({ image, images, isOpen, setIsOpen }) => {
  const [currentImage, setCurrentImage] = useState(0)

  useEffect(() => {
    if (image) {
      const current = images.indexOf(image.fluid)
      setCurrentImage(current)
    }
  }, [image, images])
  const end = images.length - 1
  const previous = () => {
    currentImage === 0
      ? setCurrentImage(end)
      : setCurrentImage(currentImage - 1)
  }
  const next = () => {
    currentImage === end
      ? setCurrentImage(0)
      : setCurrentImage(currentImage + 1)
  }
  if (image) {
    return (
      <Overlay open={isOpen} onClick={() => setIsOpen(!isOpen)}>
        <Container onClick={e => e.stopPropagation()}>
          <CloseIcon onClick={() => setIsOpen(!isOpen)} />
          <LeftArrow src={arrowLeft} alt="navigate left" onClick={previous} />
          <Img
            fluid={images[currentImage]}
            imgStyle={{
              objectFit: "cover",
            }}
            alt="room"
          />
          <RightArrow src={arrowRight} alt="navigate right" onClick={next} />
        </Container>
      </Overlay>
    )
  }
  return null
}

export default ImageModal

const Overlay = styled.div<{ open: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.5);
  display: ${({ open }) => (open ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  z-index: 2;
`
const Container = styled.div`
  position: relative;
  .gatsby-image-wrapper {
    max-height: 80vh;
    width: 80vw;
  }
`

const LeftArrow = styled.img`
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 50%;
  height: 40px;
  position: absolute;
  z-index: 1000;
  top: 50%;
  left: 32px;
  transition: left 0.3s ease;
  :hover {
    left: 24px;
  }
`
const RightArrow = styled.img`
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 50%;
  height: 40px;
  position: absolute;
  z-index: 1000;
  top: 50%;
  right: 32px;
  transition: right 0.3s ease;
  :hover {
    right: 24px;
  }
`
