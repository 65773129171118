import { graphql, useStaticQuery } from "gatsby"
import { mergePrismicPreviewData } from "gatsby-source-prismic"

export const useGalleryData = previewData => {
  const staticData = useStaticQuery(graphql`
    query {
      prismicGalleryPage {
        _previewable
        uid
        data {
          page_title {
            html
          }
          tagline {
            text
          }
          hero_image {
            alt
            fluid {
              aspectRatio
              sizes
              src
              srcSet
            }
          }
          body {
            ... on PrismicGalleryPageBodyGallery {
              slice_type
              items {
                gallery_image {
                  alt
                  fluid(maxHeight: 650, maxWidth: 450) {
                    aspectRatio
                    sizes
                    src
                    srcSet
                  }
                  fixed(width: 650, height: 450) {
                    height
                    width
                    src
                    srcSet
                  }
                }
              }
            }
            ... on PrismicGalleryPageBodySocial {
              slice_type
              primary {
                title {
                  html
                }
                text {
                  text
                }
              }
              items {
                icon {
                  alt
                  fixed(height: 35, width: 35) {
                    src
                    srcSet
                    width
                    height
                  }
                }
                social_link {
                  url
                }
              }
            }
            ... on PrismicGalleryPageBodyBook {
              slice_type
              primary {
                button_text
                link {
                  url
                  link_type
                }
                text {
                  text
                }
              }
            }
          }
        }
      }
    }
  `)
  const mergedData: any = mergePrismicPreviewData({ staticData, previewData })
  const { uid, data } = mergedData.prismicGalleryPage
  const { hero_image: image, tagline: tagline, page_title: title, body } = data
  let bookData
  let sections = []
  body.forEach(section => {
    if (section.slice_type === "book") {
      bookData = section.primary
    } else {
      sections.push(section)
    }
  })

  return {
    uid,
    image,
    title,
    tagline,
    sections,
    bookData,
  }
}
