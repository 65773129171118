import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { Text } from "../atoms/text"

const LetsBeSocial = ({ title, text, items }) => {
  return (
    <Container>
      <Title dangerouslySetInnerHTML={{ __html: title.html }} />
      <Text>{text.text}</Text>
      <SocialsContainer>
        {items.map((social, i) => (
          <Anchor key={i} href={social.social_link.url}>
            <ImageContainer>
              <Img
                fixed={social.icon.fixed}
                alt={social.icon.alt || "social media"}
              />
            </ImageContainer>
          </Anchor>
        ))}
      </SocialsContainer>
    </Container>
  )
}

export default LetsBeSocial

const Container = styled.div`
  display: grid;
  grid-gap: 8px 0;
  padding: 80px 0;
  width: 500px;
  margin: 0 auto;
  text-align: center;
  @media (max-width: 550px) {
    width: 100%;
  }
`
const Title = styled.div`
  h2 {
    margin: 1rem 0;
    color: var(--primary-text);
    font-size: 3rem;
    font-family: "Canela Light", sans-serif;
  }
`
const SocialsContainer = styled.div`
  width: 150px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
`

const Anchor = styled.a`
  .gatsby-image-wrapper {
    border-radius: 50%;
  }
`

const ImageContainer = styled.div`
  .gatsby-image-wrapper {
    max-height: 43px;
    max-width: 43px;
  }
`
