import React, { createElement, useState } from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { MdZoomOutMap } from "react-icons/md"
import { renderToStaticMarkup } from "react-dom/server"
import ImageModal from "../modals/imageModal"

const reactSvgComponentToMarkupString = (Component, props) =>
  `data:image/svg+xml,${encodeURIComponent(
    renderToStaticMarkup(createElement(Component, props))
  )}`

const PageGalley = ({ images }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [image, setImage] = useState(undefined)
  return (
    <Container>
      <ImageModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        image={image}
        images={images.map(image => image.gallery_image.fluid)}
      />
      {images.map(({ gallery_image }, i) => {
        if (i === 0) {
          return (
            <ImageContainer
              onClick={() => {
                setIsOpen(!isOpen)
                setImage(gallery_image)
              }}
              big
              area={"firstImage"}
              key={i}
            >
              <Img
                fluid={gallery_image.fluid}
                alt={gallery_image.alt || "gallery"}
              />
            </ImageContainer>
          )
        } else if (i === images.length - 1) {
          return (
            <ImageContainer
              onClick={() => {
                setIsOpen(!isOpen)
                setImage(gallery_image)
              }}
              big
              area={"lastImage"}
              key={i}
            >
              <Img
                fluid={gallery_image.fluid}
                alt={gallery_image.alt || "gallery"}
              />
            </ImageContainer>
          )
        }
        return (
          <ImageContainer
            onClick={() => {
              setIsOpen(!isOpen)
              setImage(gallery_image)
            }}
            key={i}
          >
            <Img
              fluid={gallery_image.fluid}
              alt={gallery_image.alt || "gallery"}
            />
          </ImageContainer>
        )
      })}
    </Container>
  )
}

export default PageGalley

const Container = styled.div`
  position: relative;
  display: grid;
  grid-gap: 24px;
  grid-template-areas:
    "firstImage firstImage image2 image3"
    "firstImage firstImage image4 image5"
    "image6 image7 image8 image9"
    "image10 image11 image12 image13"
    "image14 image15 lastImage lastImage"
    "image16 image17 lastImage lastImage";
  @media (max-width: 720px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "firstImage firstImage"
      "firstImage firstImage"
      "image2 image3"
      "image4 image5"
      "image6 image7"
      "image8 image9"
      "image10 image11"
      "image12 image13"
      "image14 image15"
      "image16 image17"
      "lastImage lastImage"
      "lastImage lastImage";
  }
`

const ImageContainer = styled.div<{ area?: string; big?: boolean }>`
  position: relative;
  grid-area: ${({ area }) => area};
  overflow: hidden;
  .gatsby-image-wrapper {
    transition: all 200ms ease;
    height: ${({ big }) => (big ? "424px" : " 200px")};
    :hover {
      cursor: pointer;
      transform: scale(1.05);
    }
  }
  &:hover:after {
    content: url(${reactSvgComponentToMarkupString(MdZoomOutMap, {
      color: "white",
    })});
    position: absolute;
    bottom: 4px;
    right: 4px;
    opacity: 1;
  }
  @media (max-width: 600px) {
    .gatsby-image-wrapper {
      max-height: 300px;
    }
  }
`
